import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import LinkBack from 'components/LinkBack'

import 'styles/faq-page.scss'

type Query = {
  faqPage: {
    title: string
    description?: RenderRichTextData<ContentfulRichTextGatsbyReference>
    questions: {
      question: string
      answer: RenderRichTextData<ContentfulRichTextGatsbyReference>
    }[]
    metaTitle?: string
    metaDescription?: string
  }
}

type VisibleQuestions = {
  [index: number]: boolean
}

export default function FAQ() {
  const {
    faqPage: { title, description, questions, metaTitle, metaDescription },
  } = useStaticQuery<Query>(graphql`
    query FaqPage {
      faqPage: contentfulFaqPage(title: { eq: "FAQ" }) {
        title
        description {
          raw
        }
        questions {
          question
          answer {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                file {
                  url
                }
              }
              ... on ContentfulButton {
                __typename
                contentful_id
                text
                url
              }
            }
          }
        }
        metaTitle
        metaDescription
      }
    }
  `)

  const [visibleQuestions, setVisibleQuestions] = useState(
    {} as VisibleQuestions,
  )
  function toggleQuestion(index: number) {
    setVisibleQuestions(prevVisibleQuestions => ({
      ...prevVisibleQuestions,
      [index]: !prevVisibleQuestions[index],
    }))
  }

  const answerOptions: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: {
            file: { url },
          },
        },
      }) => {
        return (
          <div className="faq-page__asset">
            <img className="faq-page__asset__image" src={url} alt="Asset" />
          </div>
        )
      },

      // eslint-disable-next-line react/display-name
      [INLINES.EMBEDDED_ENTRY]: ({
        data: {
          target: { text, url },
        },
      }) => {
        const externalUrlRegexp =
          /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/
        const internalUrlRegexp = /^\/([a-z][a-z0-9]*)(-[a-z0-9]+)*$/
        const mailtoUrlRegexp =
          /mailto:(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+(?:\.(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

        if (url.match(externalUrlRegexp) || url.match(mailtoUrlRegexp))
          return (
            <a className="faq-page__button btn btn-primary" href={url}>
              {text}
            </a>
          )
        if (url.match(internalUrlRegexp))
          return (
            <Link className="faq-page__button btn btn-primary" to={url}>
              {text}
            </Link>
          )
        return null
      },

      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
        const externalUrlRegexp =
          /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/
        const internalUrlRegexp = /^\/([a-z][a-z0-9]*)(-[a-z0-9]+)*$/
        const mailtoUrlRegexp =
          /mailto:(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+(?:\.(?:[a-zA-Z0-9!#$&'*+/=?^_`{|}~-]|%[0-9a-fA-F]{2})+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

        if (uri.match(externalUrlRegexp) || uri.match(mailtoUrlRegexp))
          return <a href={uri}>{children}</a>
        if (uri.match(internalUrlRegexp))
          return <Link to={uri}>{children}</Link>
        return null
      },
    },
  }

  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="faq-page">
          {(metaTitle || metaDescription) && (
            <Helmet>
              {metaTitle && <title>{metaTitle}</title>}
              {metaDescription && (
                <meta name="description" content={metaDescription} />
              )}
            </Helmet>
          )}

          <div className="row">
            <div className="col-lg-2">
              <LinkBack>&#8592; Go back</LinkBack>
            </div>
            <div className="col-lg-10 col-xl-8">
              <h1>{title}</h1>
              {description && (
                <div className="faq-page__description">
                  {renderRichText(description)}
                </div>
              )}

              {questions && (
                <div
                  className="faq-page__questions"
                  itemScope
                  itemType="https://schema.org/FAQPage"
                >
                  {questions
                    .filter(({ question }) => question !== 'DO NOT DELETE')
                    .map(({ question, answer }, index) => (
                      <div
                        className="faq-page__questions__item"
                        itemScope
                        itemProp="mainEntity"
                        itemType="https://schema.org/Question"
                        key={index}
                      >
                        <button
                          className={classNames(
                            'faq-page__questions__item__question',
                            {
                              'faq-page__questions__item__question--open':
                                visibleQuestions[index],
                            },
                          )}
                          itemProp="name"
                          onClick={() => toggleQuestion(index)}
                        >
                          {question}
                        </button>
                        <div
                          className={classNames(
                            'faq-page__questions__item__answer',
                            {
                              'faq-page__questions__item__answer--open':
                                visibleQuestions[index],
                            },
                          )}
                          itemScope
                          itemProp="acceptedAnswer"
                          itemType="https://schema.org/Answer"
                        >
                          {renderRichText(answer, answerOptions)}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
